import React, { useState } from "react";
import { Button, Typography, useMediaQuery } from "@mui/material";
import { Formik } from "formik";
import { diagnosisHistoryApi } from "../../../../../api/diagnosisHistoryApi";
import { useSnackbar } from "notistack";
import { useTheme } from "@mui/material/styles";
import DiagnosisEventActionButtons from "./DiagnosisEventActionButtons";
import DiagnosisEventContent from "./diagnosis-content/DiagnosisEventContent";
import { useDiagnosisHistoryValidationSchema } from "../DiagnosisHistoryValidation";
import { DiagnosisHistoryDto, PatientDto } from "../../../../../endpoints";
import { useTranslation } from "react-i18next";
import { VerticalTimelineElement } from "react-vertical-timeline-component";
import AssignmentIndTwoToneIcon from "@mui/icons-material/AssignmentIndTwoTone";
import { Link } from "react-router-dom";
import { RoutePaths } from "../../../../../routes/routePaths";
import PrintTwoToneIcon from "@mui/icons-material/PrintTwoTone";
import { DiagnosisEventContentPrintProps } from "./diagnosis-content/DiagnosisEventContentPrint";

interface DiagnosisEventProps {
  diagnosisEvent: DiagnosisHistoryDto;
  updatePatientDiagnosisHistory: () => void;
  patient: PatientDto;
}

const DiagnosisEvent: React.FC<DiagnosisEventProps> = ({
  patient,
  diagnosisEvent,
  updatePatientDiagnosisHistory,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isDeviceMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [isEditModeEnabled, setIsEditModeEnabled] = useState<boolean>(false);
  const { t } = useTranslation();
  const [overridablePrintData, setOverridablePrintData] = useState<
    DiagnosisHistoryDto | undefined
  >(undefined);

  const handleDiagnosisEventSubmit = async (
    submitForm: (() => Promise<void>) & (() => Promise<any>)
  ) => {
    await submitForm();
    setIsEditModeEnabled(!isEditModeEnabled);
  };

  const handleOverridablePrintData = (
    alteredDiagnosis: DiagnosisHistoryDto
  ) => {
    setOverridablePrintData(alteredDiagnosis);
  };

  const handleDelete = async (
    diagnosis: DiagnosisHistoryDto
  ): Promise<void> => {
    await diagnosisHistoryApi.deleteDiagnosisHistory(diagnosis);
    enqueueSnackbar(t("diagnosis.removed"), { variant: "success" });
    updatePatientDiagnosisHistory();
  };

  const handleUpdate = () => {
    setIsEditModeEnabled(!isEditModeEnabled);
  };

  const handleSubmit = (values: DiagnosisHistoryDto) => {
    diagnosisHistoryApi
      .updateDiagnosisHistory(values)
      .then(() => {
        updatePatientDiagnosisHistory();
        setIsEditModeEnabled(false);
        enqueueSnackbar(t("diagnosis.saved"), { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar(t("diagnosis.failed"), {
          variant: "error",
        });
      });
  };

  const PrintLink = (
    <Link
      to={RoutePaths.DIAGNOSIS_PRINT}
      state={
        {
          patient,
          diagnosisEvent,
          overridablePrintData,
        } as DiagnosisEventContentPrintProps
      }
    >
      <Button onClick={() => {}} variant="outlined" color="primary">
        <PrintTwoToneIcon />
      </Button>
    </Link>
  );

  return (
    <>
      <Formik
        validationSchema={useDiagnosisHistoryValidationSchema()}
        initialValues={diagnosisEvent}
        onSubmit={handleSubmit}
      >
        {({ submitForm }) => {
          return (
            <VerticalTimelineElement
              key={diagnosisEvent.id}
              contentStyle={{
                padding: "15px",
                boxShadow: "none",
                borderRadius: "0",
                borderBottom: "1px solid #eee",
              }}
              createdAt={
                isDeviceMdUp ? (
                  <Typography variant="subtitle2" color="textSecondary">
                    {new Date(diagnosisEvent.updatedAt).toLocaleDateString()}
                  </Typography>
                ) : null
              }
              icon={<AssignmentIndTwoToneIcon />}
              iconColor={theme.palette.primary.main}
              iconStyle={{ boxShadow: "none", paddingTop: "30px" }}
              contentArrowStyle={{ display: "none" }}
              date={new Date(diagnosisEvent.updatedAt).toLocaleDateString()}
            >
              <DiagnosisEventActionButtons
                isEditModeEnabled={isEditModeEnabled}
                diagnosisEvent={diagnosisEvent}
                handleSubmit={() => handleDiagnosisEventSubmit(submitForm)}
                PrintLink={PrintLink}
                handleDelete={() => handleDelete(diagnosisEvent)}
                handleUpdate={handleUpdate}
                handleOverridablePrintData={handleOverridablePrintData}
              />
              <DiagnosisEventContent
                isEditModeEnabled={isEditModeEnabled}
                diagnosisEvent={diagnosisEvent}
              />
            </VerticalTimelineElement>
          );
        }}
      </Formik>
    </>
  );
};

export default DiagnosisEvent;
