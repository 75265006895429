import React from "react";
import Login from "./routes/login/Login";
import Register from "./routes/register/Register";
import Dashboard from "./routes/dashboard/Dashboard";
import { SnackbarProvider } from "notistack";
import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import { ThemeProvider as StyleComponentsThemeProvider } from "styled-components";
import { theme } from "./theme/theme";
import { RoutePaths } from "./routes/routePaths";
import GlobalErrorHandler from "./exception/GlobalErrorHandler";
import UserContextProvider from "./contexts/UserContextProvider";
import PrivateRoute from "./auth/PrivateRoute";
import { UserConfirmation } from "./routes/user-confirmation/UserConfirmation";
import { Route, Routes } from "react-router-dom";
import { DiagnosisEventContentPrint } from "./pages/patient/show/diagnosis-history/diagnosis-event/diagnosis-content/DiagnosisEventContentPrint";

const App = (): any => {
  return (
    <GlobalErrorHandler>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <StyleComponentsThemeProvider theme={theme}>
            <SnackbarProvider>
              <UserContextProvider>
                <Routes>
                  <Route path={RoutePaths.LOGIN} Component={Login} />
                  <Route path={RoutePaths.REGISTER} Component={Register} />
                  <Route
                    path={RoutePaths.USER_CONFIRMATION_INDEX}
                    Component={UserConfirmation}
                  />
                  <Route
                    path={RoutePaths.DIAGNOSIS_PRINT}
                    Component={DiagnosisEventContentPrint}
                  />
                  <Route
                    path="*"
                    Component={() => <PrivateRoute component={Dashboard} />}
                  />
                </Routes>
              </UserContextProvider>
            </SnackbarProvider>
          </StyleComponentsThemeProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </GlobalErrorHandler>
  );
};

export default App;
