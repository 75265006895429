import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { DiagnosisHistoryDto } from "../../../../../endpoints";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";

interface OverridablePrintDataModalProps {
  open: boolean;
  diagnosisEvent: DiagnosisHistoryDto;
  handleOverridablePrintData: (
    overridablePrintData: DiagnosisHistoryDto
  ) => void;
  PrintLink: React.ReactNode;
  handleClose: () => void;
}

export const OverridablePrintDataModal: React.FC<
  OverridablePrintDataModalProps
> = ({
  handleClose,
  open,
  diagnosisEvent,
  handleOverridablePrintData,
  PrintLink,
}: OverridablePrintDataModalProps) => {
  const { t } = useTranslation();

  const handleDateOverride = (val: Dayjs | null): void => {
    if (!val) {
      return;
    }
    handleOverridablePrintData({
      ...diagnosisEvent,
      updatedAt: val.toDate(),
    });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      aria-labelledby="override-dialog-title"
      onClose={handleClose}
      open={open}
    >
      <DialogTitle id="override-dialog-title">
        <Typography variant="body1">{t("override.patient.data")}</Typography>
      </DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={dayjs(diagnosisEvent.updatedAt)}
            onChange={handleDateOverride}
            label={t("patient.date.time")}
            sx={{ marginTop: 1.5 }}
          />
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleClose}
          color="error"
          variant="outlined"
          sx={{ marginRight: "10px" }}
        >
          {t("no.cancel")}
        </Button>
        {PrintLink && PrintLink}
      </DialogActions>
    </Dialog>
  );
};
