import React from "react";
import { Link } from "react-router-dom";
import { ListItem } from "@mui/material";

function ListItemLink(props) {
  const { to, children, onClick, ...rest } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        // @ts-ignore
        <Link ref={ref} to={to} {...itemProps} />
      )),
    [to]
  );

  return (
    <li>
      <ListItem onClick={onClick} {...rest} button component={renderLink}>
        {children}
      </ListItem>
    </li>
  );
}

export default ListItemLink;
