import React, { MutableRefObject, useEffect, useMemo, useRef } from "react";
import DiagnosisEventContent from "./DiagnosisEventContent";
import GridContainer from "../../../../../../shared/widgets/container/GridContainer";
import GridItem from "../../../../../../shared/widgets/container/GridItem";
import LogoImage from "../../../../../../shared/images/logo.png";
import { LogoImage as Logo } from "../../../../../../shared/widgets/images/LogoImage";
import { Typography } from "@mui/material";
import { DiagnosisHistoryDto, PatientDto } from "../../../../../../endpoints";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

export interface DiagnosisEventContentPrintProps {
  patient: PatientDto;
  diagnosisEvent: DiagnosisHistoryDto;
  overridablePrintData: DiagnosisHistoryDto | undefined;
  refPrint: MutableRefObject<null>;
}

export const DiagnosisEventContentPrint = (): JSX.Element => {
  const { state } = useLocation();
  const {
    patient,
    diagnosisEvent,
    overridablePrintData,
  }: DiagnosisEventContentPrintProps = state;
  const componentForPrintingRef = useRef(null);
  const { t } = useTranslation();

  const handlePrint = useReactToPrint({
    contentRef: componentForPrintingRef,
  });

  const formattedDate = useMemo(() => {
    if (overridablePrintData?.updatedAt) {
      return overridablePrintData.updatedAt.toLocaleDateString();
    }

    return new Intl.DateTimeFormat("mk-MK").format(Date.now());
  }, [diagnosisEvent.updatedAt, overridablePrintData]);

  useEffect(() => {
    handlePrint();
  }, []);

  return (
    <div
      ref={componentForPrintingRef}
      style={{
        margin: "25px",
      }}
    >
      <GridContainer
        style={{
          height: "100vh",
          display: "flex",
          alignContent: "stretch",
        }}
      >
        <GridItem xs={12}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              verticalAlign: "middle",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <Logo src={LogoImage} />
          </div>
        </GridItem>
        <GridItem xs={12}>
          <Typography variant="body1">{t("patient.full.name")}</Typography>
          <Typography variant="body2">{patient.fullName}</Typography>
          <br />

          <DiagnosisEventContent
            isEditModeEnabled={false}
            diagnosisEvent={diagnosisEvent}
          />
        </GridItem>
        <GridContainer
          style={{
            display: "flex",
            marginBottom: "25px",
            marginTop: "auto",
            marginLeft: "3px",
            alignSelf: "baseline",
            alignContent: "flex-end",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <GridItem xs={4}>
            <Typography variant="body2">{t("examination.done.by")}</Typography>
            <Typography variant="body2" style={{ marginBottom: "1px" }}>
              {t("doctor.accolades")} <br /> {t("dermatovenereologist")}
            </Typography>
            <Typography variant="body2" style={{ marginTop: "1px" }}>
              {t("lidija")}
            </Typography>
          </GridItem>

          <GridItem
            xs={4}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography variant="body2">{formattedDate}</Typography>
          </GridItem>

          <GridItem xs={4}>
            <Typography variant="body2">{t("company")}</Typography>
            <Typography variant="body2">
              {t("street")}: Васил Ѓоргов 20 Б
              <br />
              {t("contact")}: (+389) 070 257 668
              <br />
              {t("email")}: info@bonaderma.xyz
            </Typography>
          </GridItem>
        </GridContainer>
      </GridContainer>
    </div>
  );
};
